<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Duplicate ScanLogs</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn icon class="ml-10" right @click="getDocData">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-toolbar color="secondary" flat dense>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="options.FromDate"
              label="Start Date"
              readonly
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.FromDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="options.ToDate"
              label="End Date"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.ToDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="docData"
              :search="search"
              class="elevation-1"
              :options.sync="options"
              :server-items-length="totalItems"
              :server-search="true"
            >
              <!-- start of posting date template -->
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("Do MMM YYYY")
              }}</template>
              <!-- end of posting date template -->

              <!-- action template -->
              <!-- <template
                    v-slot:item.action="{ item }"
                    v-if="item.ScanLogID"
                  >
                    <v-btn
                      icon
                      :to="`/gpm-scan-logs/${item.ScanLogID}`"
                    >
                      <v-icon color="green">mdi-eye</v-icon>
                    </v-btn>
                  </template> -->
              <!-- end of action template -->
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog v-model="errorDocDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="errorDocDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:
              </v-col>
              <v-col cols="12">
                <span>
                  {{ this.ErrorMessage }}
                </span>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn color="green" dark @click="errorDocDialog = false"
                    >Close</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar ref="snackbar"></snackbar>
    <!-- end of modal-->
  </v-container>
</template>
    
    <script>
export default {
  data: () => ({
    search: "",
    docData: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    headers: [
      { text: "Gate", value: "gates.Name" },
      { text: "creator", value: "creator.name" },
      { text: "creator", value: "creator.phone_number" },
      { text: "Document Type", value: "objecttype.DocumentName" },
      { text: "Document Number", value: "DocNum" },
      { text: "Scan State", value: "ResultDesc" },
      { text: "Scan Time", value: "created_at" },
      { text: "Comment", value: "Comment" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 50,
      page: 1,
      FromDate: null,
      ToDate: null,
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getDocData();
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      if (this.search.length >= 3) {
        this.getDocData();
      }
      if (this.search.length == 0) {
        this.getDocData();
      }
    },
  },
  methods: {
    defaultDates() {
      if (!this.options.FromDate) {
        const today = new Date();
        const firstDayOfWeek = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 1
        );
        this.options.FromDate = firstDayOfWeek.toISOString().substr(0, 10);
      }

      if (!this.options.ToDate) {
        const lastDayOfWeek = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - new Date().getDay() + 7
        );
        this.options.ToDate = lastDayOfWeek.toISOString().substr(0, 10);
      }
    },

    getDocData() {
      const self = this;
      this.loading = true;
      this.docData = [];
      //?page=${this.options.page}&per_page=${this.options.itemsPerPage}&search=${this.search}`
      let url = `/duplicateLogsReport`;
      if (this.options.FromDate) {
        url += `${url.includes("?") ? "&" : "?"}FromDate=${
          this.options.FromDate
        }`;
      }
      if (this.options.ToDate) {
        url += `${url.includes("?") ? "&" : "?"}ToDate=${this.options.ToDate}`;
      }
      url += `${url.includes("?") ? "&" : "?"}page=${
        this.options.page
      }&per_page=${this.options.itemsPerPage}&search=${this.search}`;

      this.$store
        .dispatch("get", url)
        .then((res) => {
          console.log(res);
          if (res.ResultCode == 1200) {
            self.docData = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
          if (res.ResultCode == 1500) {
            this.$store.commit("loader", false);
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
    updateRange() {
      if (
        this.options.FromDate &&
        this.options.ToDate &&
        this.options.FromDate > this.options.ToDate
      ) {
        this.$refs.snackbar.show(
          "Start date cannot be greater than To date",
          "red"
        );

        this.options.ToDate = "";
      }
    },
  },
  created() {
    this.defaultDates();
  },
};
</script>